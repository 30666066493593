.Imgbg {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.diff {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.03);
}

.horizontal-scroll-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;
}

.child-element {
    flex: 0 0 auto;
    width: 200px;
    height: 200px;
    background-color: lightblue;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.content-below {
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
}

.imgtext {
    font-weight: 600;
    text-align: center;
}


@media only screen and (max-width: 500px) {
    .imgtext {
        font-size: 35px;
    }
}

* {
    box-sizing: border-box
}

.fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from {
        opacity: .4
    }

    to {
        opacity: 1
    }
}

.slideshow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.slideshow-image-container {
    width: 97.5%;
    height: 63vh;
    overflow: hidden;
    position: relative;
}

.slideshow-image {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: opacity 1s ease-in-out;
}

.slideshow-image.active {
    opacity: 1;
}

.slideshow button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.slideshow button:hover {
    background-color: #0056b3;
}