@media only screen and (min-device-width: 250px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .contactus {
    font-size: 17px;
    font-weight: 649;
  }

  .headingsms {
    text-align: center;
    align-items: center;
  }

  .nav {
    text-align: center;
    align-items: center;
  }
}

@media only screen and (min-device-width: 450px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .headingsms {
    text-align: center;
    align-items: center;
  }

  .nav {
    text-align: center;
    align-items: center;
  }
}

@media only screen and (min-device-width: 450px) and (max-device-width: 770px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .headingsms {
    text-align: center;
    align-items: center;
  }

  .nav {
    text-align: center;
    align-items: center;
  }
}