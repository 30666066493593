@media only screen and (min-device-width: 250px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .usertile {
        display: none;
    }
}

@media only screen and (min-device-width: 450px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .usertile {
        display: none;
    }
}