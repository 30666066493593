::-webkit-scrollbar {
  display: none;
}

.header1 {
  transition: transform 11s ease-in-out;
}

.header1.expanded {
  transform: translateX(350px);
}