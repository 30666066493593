#slideshow {
    width: 100%;
    height: 450px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 2.2s ease-in-out;
}

.slide.active {
    transform: translateX(0%);
    opacity: 1;
}